import { SystemLanguage } from '@zeiss/ng-vis-common/types';
var enGB = {
  skillmatrix: {
    routes: {
      title: 'Skills Matrix',
      desc: 'View and manage skills matrix',
      skills: {
        desc: 'Skills overview',
        title: 'Skills Overview'
      },
      metadata: {
        desc: 'Manage metadata',
        title: 'Metadata'
      }
    }
  }
};
var esES = {
  skillmatrix: {}
};
var deDE = {
  skillmatrix: {}
};
const SKILLMATRIX_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: {
    ...enGB
  },
  [SystemLanguage['es-ES']]: {
    ...esES
  },
  [SystemLanguage['de-DE']]: {
    ...deDE
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { SKILLMATRIX_TRANSLATIONS };
